// Here you can add other styles
button.btn.btn-primary,
button.btn.btn-primary.ms-4 {
  background: linear-gradient(to right, #B412CD, #340750) !important;
  color: white !important;
  border-color: #B412CD !important;
}
  


  .dragging {
    border: 2px dashed #1890ff; /* Add a dashed border when dragging */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow effect */
    transition: background-color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
    cursor: grabbing; /* Change cursor when dragging */
  }

  .border {
    border: 1px solid #6c757d; /* Add border to list items */
  }

  /* Custom style for the entered text */
  .custom-editor .jodit-wysiwyg p {
     color: rgb(0, 0, 0); 
   }
  .custom-table-container {
    background-color: rgb(104, 25, 25);
    color: rgb(37, 13, 94);
   }



  .custom-ant-table .ant-table {
    background-color: #222;
    color: #fff;
  }
  
  .custom-ant-table .ant-table-header {
    background-color: #333;
    color: #fff;
  }
  
  .custom-ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #444;
  }
  
  .custom-ant-table .ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell {
    color: #fff;
  }
  
  .custom-ant-table .ant-table-tbody > tr.ant-table-row.ant-table-row-selected > td {
    background-color: #555;
  }
  
  .custom-ant-table .ant-table-tbody > tr.ant-table-row.ant-table-row-selected > td.ant-table-cell {
    color: #fff;
  }
  
  .custom-ant-table .ant-table-thead > tr > th {
    color: #fff;
    background-color: #333;
  }
  
  .custom-ant-table .ant-table-thead th.ant-table-column-has-sorters:hover,
  .custom-ant-table .ant-table-thead th.ant-table-column-has-filters:hover,
  .custom-ant-table .ant-table-thead th.ant-table-column-sort {
    color: #fff;
    background-color: #333;
  }
  
  .custom-ant-table .ant-table-column-sorter-up.active,
  .custom-ant-table .ant-table-column-sorter-down.active {
    color: #fff;
    background-color: transparent;
  }
  
  .custom-ant-table .ant-table-column-sorter-up.active > .ant-table-column-sorter-inner,
  .custom-ant-table .ant-table-column-sorter-down.active > .ant-table-column-sorter-inner {
    color: #8d1717;
  }
  
  .custom-ant-table .ant-pagination-item:hover a {
    color: #1890ff;
  }
  
  .custom-ant-table .ant-pagination-item-active a {
    color: #1890ff;
  }
  
  .custom-ant-table .ant-pagination-prev:hover .ant-pagination-item-link,
  .custom-ant-table .ant-pagination-next:hover .ant-pagination-item-link {
    color: #1890ff;
  }
  
  .custom-ant-table .ant-table-column-sort {
    background-color: #333; /* Set background color for sorted column */
    color: #fff; /* Set text color for sorted column */
  }

  .custom-ant-table:where(.css-dev-only-do-not-override-kghr11).ant-table-wrapper .ant-table-tbody >tr >td {
    background-color: #333;
  }
  .bg-primary{
    background: linear-gradient(to right, #B412CD, #340750) !important;
  }
  .bg-users{
    background: linear-gradient(to right, #B412CD, #340750) !important;
  }
  .bg-about{
    background: linear-gradient(to right, #4285F3, #101849) !important;
  }
  .bg-privacy{
    background: linear-gradient(to right, #15B256, #0F2719) !important;
  }
  .bg-terms{
    background: linear-gradient(to right, #C85F0D, #471F00) !important;
  }
  .bg-faq{
    background: linear-gradient(to right, #83A8B6, #3C5660) !important;
  }
  .bg-contact{
    background: linear-gradient(to right, #F15DA0, #990045) !important;
  }